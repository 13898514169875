import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Animasi Fade In
const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px); /* Start 20px above */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move to original position */
  }
`;

// Animasi Fade Out
const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0); /* Start at original position */
  }
  to {
    opacity: 0;
    transform: translateY(20px); /* Move 20px down */
  }
`;

// Animasi untuk progress bar
const progressBarAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

// Styled Container untuk Intro
const IntroContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  font-family: "Poppins", sans-serif;
`;

// Styled Text untuk animasi teks
const IntroText = styled.h1<{ fadeIn: boolean; fadeOut: boolean }>`
  color: #fff;
  font-size: 3rem;
  animation: ${({ fadeIn }) => (fadeIn ? fadeInAnimation : '')} 1.5s forwards,
             ${({ fadeOut }) => (fadeOut ? fadeOutAnimation : '')} 1.5s forwards 1.5s;
  margin-bottom: 20px;
`;

// Styled Enthusiast Text
const EnthusiastText = styled.span<{ highlight: boolean }>`
  color: ${({ highlight }) => (highlight ? '#FD3A4A' : '#fff')};
  transition: color 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: ${({ highlight }) => (highlight ? 'translateY(-10px)' : 'translateY(0)')};
`;

// Styled Progress Bar Container
// Styled Progress Bar Container
const ProgressBarContainer = styled.div`
  width: 10%; /* Ubah ukuran progress bar */
  height: 5px;
  background-color: #333;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
`;


// Styled Progress Bar
const ProgressBar = styled.div`
 height: 100%;
  background-color: #FD3A4A;
  animation: ${progressBarAnimation} 8s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
`;

// Komponen Intro
export function Intro({ onComplete }: { onComplete: () => void }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [highlightEnthusiast, setHighlightEnthusiast] = useState(false); // State to handle color change
  const steps = ['Hello !', 'This is Septian', "Tech Enthusiast."];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentStep < steps.length - 1) {
        setCurrentStep((prev) => prev + 1);
      } else {
        // After the last step, trigger onComplete
        setTimeout(() => {
          onComplete(); // Notify parent that intro is complete
        }, 1500); // Wait for the fade out animation to complete before notifying
      }
    }, 2000); // Display each step for 2 seconds

    return () => clearTimeout(timer);
  }, [currentStep, steps.length, onComplete]);

  useEffect(() => {
    if (currentStep === 2) {
      // After 2 seconds, change "Enthusiast" color and move it up
      const enthusiastTimer = setTimeout(() => {
        setHighlightEnthusiast(true);
      }, 2000); // Delay of 2 seconds before animation

      return () => clearTimeout(enthusiastTimer);
    }
  }, [currentStep]);

  return (
    <IntroContainer>
      <IntroText 
        fadeIn={currentStep % 2 === 0} // Activate fadeIn when currentStep is even
        fadeOut={currentStep % 2 === 1} // Activate fadeOut when currentStep is odd
      >
        {currentStep === 2 ? (
          <>
            I'm <EnthusiastText highlight={highlightEnthusiast}>Junior Front-End.</EnthusiastText>
          </>
        ) : (
          steps[currentStep]
        )}
      </IntroText>

      {/* Progress Bar di bawah teks */}
      <ProgressBarContainer>
        <ProgressBar />
      </ProgressBarContainer>
    </IntroContainer>
  );
}

export default Intro;
